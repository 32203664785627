import React from "react";
import Layout from "../components/layout";
import BlogComponent from "../components/blogpagecomponent"
function blogs() {
    return(
        <Layout headimg={"/images/top-logo-2.webp"} headclr={"black-list"} headbg={"whitee-bg"} callicon ={"/images/call-answer (1).webp"}>
            <BlogComponent/>
        </Layout>
    )
}
export default blogs