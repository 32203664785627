import React from "react";
import ApplicationCard from "../applicationcards";
import Story from "../featuredstories"

function blogPageComponent() {
    return (
        <div className="blog-bg">
            <div className="container">
                <div className="col-md-12 d-flex blog-main-col">
                    <ApplicationCard cardsSize={"col-sm-6"} mainColumn={"col-sm-12"}/>
                    <Story/>
                </div>
            </div></div>
            )
            }
 export default blogPageComponent;